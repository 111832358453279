import React, { useEffect, useRef, useState } from 'react';

interface ScoreViewerProps {
    score_id: string;
    currentBeat: number;
    currentBar: number;
    getBackendDomain: () => string;
}

const ScoreViewer: React.FC<ScoreViewerProps> = ({
                                                     score_id,
                                                     currentBeat,
                                                     currentBar,
                                                     getBackendDomain,
                                                 }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const verovioRef = useRef<any>(null);
    const measureIdsRef = useRef<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number | null>(1);

    useEffect(() => {
        const script = document.createElement('script');
        script.src =
            'https://www.verovio.org/javascript/latest/verovio-toolkit-wasm.js';
        script.async = true;
        script.onload = () => {
            // @ts-ignore
            window.verovio.module.onRuntimeInitialized = async () => {
                // @ts-ignore
                verovioRef.current = new window.verovio.toolkit();
                await loadScore();
            };
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (verovioRef.current) {
            loadScore();
        }
    }, [score_id]);

    useEffect(() => {
        if (verovioRef.current && currentPage !== null) {
            renderScore();
        }
    }, [currentPage]);

    useEffect(() => {
        if (verovioRef.current) {
            highlightMeasure();
        }
    }, [currentBar, verovioRef.current]);

    useEffect(() => {
        const handleResize = () => {
            if (verovioRef.current) {
                renderScore();
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loadScore = async () => {
        if (!verovioRef.current || !score_id) return;

        try {
            const apiBase = getBackendDomain();
            const url = `https://${apiBase}/score/${score_id}`;
            const response = await fetch(url);
            const musicXml = await response.text();

            // Set options before loading data
            const options = {
                xmlId: true,
                svgViewBox: true,
                generateIds: true,
            };
            verovioRef.current.setOptions(options);

            verovioRef.current.loadData(musicXml);

            // Get MEI data
            const meiData = verovioRef.current.getMEI();

            // Parse MEI to get measure IDs
            const parser = new DOMParser();
            const meiDoc = parser.parseFromString(meiData, 'application/xml');
            const measureElements = meiDoc.getElementsByTagName('measure');

            // Build an array of measure IDs
            const measureIds: string[] = [];
            for (let i = 0; i < measureElements.length; i++) {
                const measureElement = measureElements[i];
                const measureId = measureElement.getAttribute('xml:id');
                if (measureId) {
                    measureIds.push(measureId);
                }
            }

            // Store measure IDs in the ref
            measureIdsRef.current = measureIds;

            renderScore();
        } catch (error) {
            console.error('Error loading score:', error);
        }
    };

    const renderScore = () => {
        if (containerRef.current && verovioRef.current && currentPage !== null) {
            const containerWidth = containerRef.current.clientWidth;

            // Standard A4 page dimensions in tenths of millimeters
            const pageWidth = 2100;
            const pageHeight = 2970;

            const scale = (containerWidth / pageWidth) * 100;

            const options = {
                scale: scale,
                pageWidth: pageWidth,
                pageHeight: pageHeight,
                adjustPageHeight: false,
                breaks: 'auto',
                header: 'none',
                footer: 'none',
                unit: 10, // tenths of millimeters
            };
            verovioRef.current.setOptions(options);
            const svg = verovioRef.current.renderToSVG(currentPage);
            containerRef.current.innerHTML = svg;

            // Set background color and inject styles
            const svgElement = containerRef.current.querySelector('svg');
            if (svgElement) {
                svgElement.style.backgroundColor = 'white';

                // Inject styles into the SVG
                const styleElement = document.createElementNS(
                    'http://www.w3.org/2000/svg',
                    'style'
                );
                styleElement.textContent = `
                    g.highlighted-measure * {
                        stroke: red !important;
                        stroke-width: 3px !important;
                        fill: red !important;
                    }
                `;
                svgElement.insertBefore(styleElement, svgElement.firstChild);
            }
        }
    };

    const highlightMeasure = () => {
        if (verovioRef.current && containerRef.current) {
            const measureIds = measureIdsRef.current;
            const totalMeasures = measureIds.length;

            if (currentBar < 0 || currentBar >= totalMeasures) {
                console.warn('currentBar exceeds total measures.');
                return;
            }

            const measureId = measureIds[currentBar];

            // Get the page containing this measure
            const measurePage = verovioRef.current.getPageWithElement(measureId);

            if (measurePage !== currentPage) {
                setCurrentPage(measurePage);
                return;
            }

            // After rendering, find the measure in the SVG
            const svgElement = containerRef.current.querySelector('svg');
            if (svgElement) {
                const measureElement = svgElement.querySelector(
                    `g[id="${measureId}"]`
                );

                if (measureElement) {
                    // Remove previous highlights
                    const previousHighlights = svgElement.querySelectorAll(
                        '.highlighted-measure'
                    );
                    previousHighlights.forEach((el) =>
                        el.classList.remove('highlighted-measure')
                    );

                    // Add highlight to current measure
                    measureElement.classList.add('highlighted-measure');

                    // Scroll to the measure if it's not in view
                    if (!isElementInViewport(measureElement)) {
                        measureElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                        });
                    }
                } else {
                    console.warn(
                        'Measure element not found in SVG for measure ID:',
                        measureId
                    );
                }
            }
        }
    };

    const isElementInViewport = (el: Element) => {
        const rect = el.getBoundingClientRect();
        const containerRect = containerRef.current!.getBoundingClientRect();
        return (
            rect.top >= containerRect.top && rect.bottom <= containerRect.bottom
        );
    };

    return (
        <div
            ref={containerRef}
            style={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                backgroundColor: 'white',
            }}
        >
            {/* Styles are injected directly into the SVG */}
        </div>
    );
};

export default ScoreViewer;
