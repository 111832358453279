import React, {Component, createRef} from 'react';
import {isIOS, osVersion, isMobileSafari, isSafari} from "react-device-detect";
import { FaTheaterMasks, FaMusic } from 'react-icons/fa';

import {ScriptCache} from "../../ScriptCache";

import IWebrtcConnectorProperties from "./IWebrtcConnectorProperties";
import {IGestureMatch} from "../../IGestureMatch";
import {FormattedMessage} from 'react-intl';

import EmotionVoronoi from '../EmotionVoronoi/EmotionVoronoi';

import './WebrtcConnector.css';
import {getBackendDomain} from "../../util/Api";
import ScoreViewer from "../ScoreViewer/ScoreViewer";

const BandwidthHandler = (function () {
    function setBAS(sdp: any, bandwidth: any, isScreen: any) {
        // @ts-ignore
        if (!!navigator.mozGetUserMedia || !bandwidth) {
            return sdp;
        }

        if (isScreen) {
            if (!bandwidth.screen) {
                console.warn('It seems that you are not using bandwidth for screen. Screen sharing is expected to fail.');
            } else if (bandwidth.screen < 300) {
                console.warn('It seems that you are using wrong bandwidth value for screen. Screen sharing is expected to fail.');
            }
        }

        // if screen; must use at least 300kbs
        if (bandwidth.screen && isScreen) {
            sdp = sdp.replace(/b=AS([^\r\n]+\r\n)/g, '');
            sdp = sdp.replace(/a=mid:video\r\n/g, 'a=mid:video\r\nb=AS:' + bandwidth.screen + '\r\n');
        }

        // remove existing bandwidth lines
        if (bandwidth.audio || bandwidth.video || bandwidth.data) {
            sdp = sdp.replace(/b=AS([^\r\n]+\r\n)/g, '');
        }

        if (bandwidth.audio) {
            sdp = sdp.replace(/a=mid:audio\r\n/g, 'a=mid:audio\r\nb=AS:' + bandwidth.audio + '\r\n');
        }

        if (bandwidth.video) {
            sdp = sdp.replace(/a=mid:video\r\n/g, 'a=mid:video\r\nb=AS:' + (isScreen ? bandwidth.screen : bandwidth.video) + '\r\n');
        }

        return sdp;
    }

    // Find the line in sdpLines that starts with |prefix|, and, if specified,
    // contains |substr| (case-insensitive search).
    function findLine(sdpLines: any, prefix: any, substr: any) {
        return findLineInRange(sdpLines, 0, -1, prefix, substr);
    }

    // Find the line in sdpLines[startLine...endLine - 1] that starts with |prefix|
    // and, if specified, contains |substr| (case-insensitive search).
    function findLineInRange(sdpLines: any, startLine: any, endLine: any, prefix: any, substr: any) {
        const realEndLine = endLine !== -1 ? endLine : sdpLines.length;
        for (var i = startLine; i < realEndLine; ++i) {
            if (sdpLines[i].indexOf(prefix) === 0) {
                if (!substr ||
                    sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
                    return i;
                }
            }
        }
        return null;
    }

    // Gets the codec payload type from an a=rtpmap:X line.
    function getCodecPayloadType(sdpLine: any) {
        const pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+');
        const result = sdpLine.match(pattern);
        return (result && result.length === 2) ? result[1] : null;
    }

    function setVideoBitrates(sdp: any, params: any) {
        params = params || {};
        const xgoogle_min_bitrate = params.min;
        const xgoogle_max_bitrate = params.max;

        const sdpLines = sdp.split('\r\n');

        // VP8
        const vp8Index = findLine(sdpLines, 'a=rtpmap', 'VP8/90000');
        let vp8Payload;
        if (vp8Index) {
            vp8Payload = getCodecPayloadType(sdpLines[vp8Index]);
            console.log("vp8Payload", vp8Payload)
        }

        if (!vp8Payload) {
            return sdp;
        }

        const rtxIndex = findLine(sdpLines, 'a=rtpmap', 'rtx/90000');
        let rtxPayload;
        if (rtxIndex) {
            rtxPayload = getCodecPayloadType(sdpLines[rtxIndex]);
            console.log("rtxPayload", rtxPayload)
        }

        if (!rtxIndex) {
            return sdp;
        }

        const rtxFmtpLineIndex = findLine(sdpLines, 'a=fmtp:' + rtxPayload.toString(), "");
        if (rtxFmtpLineIndex !== null) {
            let appendrtxNext = '\r\n';
            appendrtxNext += 'a=fmtp:' + vp8Payload + ' x-google-min-bitrate=' + (xgoogle_min_bitrate || '228') + '; x-google-max-bitrate=' + (xgoogle_max_bitrate || '228');
            sdpLines[rtxFmtpLineIndex] = sdpLines[rtxFmtpLineIndex].concat(appendrtxNext);
            console.log("sdp line", sdpLines[rtxFmtpLineIndex]);
            sdp = sdpLines.join('\r\n');
        }

        return sdp;
    }

    function setOpusAttributes(sdp: any, params: any) {
        params = params || {};

        const sdpLines = sdp.split('\r\n');

        // Opus
        const opusIndex = findLine(sdpLines, 'a=rtpmap', 'opus/48000');
        let opusPayload;
        if (opusIndex) {
            opusPayload = getCodecPayloadType(sdpLines[opusIndex]);
        }

        if (!opusPayload) {
            return sdp;
        }

        const opusFmtpLineIndex = findLine(sdpLines, 'a=fmtp:' + opusPayload.toString(), "");
        if (opusFmtpLineIndex === null) {
            console.log("no opus fmt line");
            return sdp;
        }

        let appendOpusNext = '';
        appendOpusNext += '; stereo=' + (typeof params.stereo != 'undefined' ? params.stereo : '1');
        appendOpusNext += '; sprop-stereo=' + (typeof params['sprop-stereo'] != 'undefined' ? params['sprop-stereo'] : '1');

        if (typeof params.maxaveragebitrate != 'undefined') {
            appendOpusNext += '; maxaveragebitrate=' + (params.maxaveragebitrate || 128 * 1024 * 8);
        }

        if (typeof params.maxplaybackrate != 'undefined') {
            appendOpusNext += '; maxplaybackrate=' + (params.maxplaybackrate || 128 * 1024 * 8);
        }

        if (typeof params.cbr != 'undefined') {
            appendOpusNext += '; cbr=' + (typeof params.cbr != 'undefined' ? params.cbr : '1');
        }

        if (typeof params.useinbandfec != 'undefined') {
            appendOpusNext += '; useinbandfec=' + params.useinbandfec;
        }

        if (typeof params.usedtx != 'undefined') {
            appendOpusNext += '; usedtx=' + params.usedtx;
        }

        if (typeof params.maxptime != 'undefined') {
            appendOpusNext += '\r\na=maxptime:' + params.maxptime;
        }

        sdpLines[opusFmtpLineIndex] = sdpLines[opusFmtpLineIndex].concat(appendOpusNext);

        sdp = sdpLines.join('\r\n');
        return sdp;
    }

    return {
        setApplicationSpecificBandwidth: function (sdp: any, bandwidth: any, isScreen: any) {
            return setBAS(sdp, bandwidth, isScreen);
        },
        setVideoBitrates: function (sdp: any, params: any) {
            return setVideoBitrates(sdp, params);
        },
        setOpusAttributes: function (sdp: any, params: any) {
            return setOpusAttributes(sdp, params);
        }
    };
})();

export async function uploadScore(file: File, userId: string, scoreName: string): Promise<void> {
    const formData = new FormData();
    formData.append('score', file);
    formData.append('userId', userId);
    formData.append('scoreName', scoreName);

    try {
        const domain = getBackendDomain();
        const options: RequestInit = {
            method: 'POST',
            body: formData as BodyInit,
        };

        const response = await fetch('https://' + domain + '/upload-score', options);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Upload successful:', result.message);
    } catch (error) {
        console.error('Error uploading file:', error);
    }
}

interface WebrtcConnectorState {
    useStun: boolean;
    emotionalResponse: any;
    showScore: boolean;
    score_id: string;
    currentBeat: number;
    currentBar: number;
}

class WebrtcConnector extends Component<IWebrtcConnectorProperties, WebrtcConnectorState> {
    private audio: any;
    private video: any;
    private video_echo: any;
    private pc?: RTCPeerConnection;
    private dc?: RTCDataChannel;
    private constraints: MediaStreamConstraints;
    private videoConstraints: MediaTrackConstraints;
    private listener: any;
    private connectionInterval: any;

    public constructor(props: any) {
        super(props);

        this.state = {
            useStun: false,
            emotionalResponse: {},
            showScore: false,
            score_id: '',
            currentBeat: 0,
            currentBar: 0,
        };

        this.audio = createRef();
        this.video = createRef();
        this.video_echo = createRef();

        this.constraints = {
            audio: isIOS || isMobileSafari ? true : {
                echoCancellation: false,
                // @ts-ignore
                autoGainControl: false,
                noiseSuppression: false,
                // @ts-ignore
                highpassFilter: {ideal: false},
                typingNoiseDetection: {ideal: false},
                audioMirroring: {ideal: false},
                sampleRate: 48000,
                sampleSize: 16,
                volume: 1.0
            },
        };

        this.videoConstraints = { facingMode: { ideal: "user" }, height: 640, width: 640 };
    }

    componentDidMount(): void {

        if (!this.props.backendConnected) {
            this.start();
        }

        this.connectionInterval = setInterval(this.reconnect.bind(this), 15000);

        const iOS = isIOS || isMobileSafari;
        const eventName = iOS ? 'pagehide' : 'beforeunload';
        const listener = (event: any) => {
            console.log("stop streaming on page unloading/hiding");
            try {
                this.stop();
            } catch (e) {
                console.error(e);
            }
        };
        this.listener = listener;
        window.addEventListener(eventName, listener);

        window.addEventListener("pageshow", (event: PageTransitionEvent) => {
            if (!this.props.backendConnected) {
                this.start();
            }
        });
    }

    componentWillUnmount(): void {
        console.log('componentWillUnmount()');
        this.stop();

        const iOS = isIOS || isMobileSafari;
        const eventName = iOS ? 'pagehide' : 'beforeunload';
        window.removeEventListener(eventName, this.listener);
        clearInterval(this.connectionInterval)
    }

    private reconnect() {
        if (!this.props.backendConnected) {
            console.log('Trying to reconnect.');
            this.stop();
            this.start();
        } else {
            console.log('Reconnect not needed.');
        }
    }

    private lastFetchTime: number = 0;
    private isFetching: boolean = false;

    private async fetchAndCacheScore(score_id: string) {
        const currentTime = Date.now();
        if (this.isFetching && currentTime - this.lastFetchTime < 30000) {
            return; // Don't fetch if already fetching and less than 30 seconds have passed
        }

        this.isFetching = true;
        this.lastFetchTime = currentTime;

        const apiBase = getBackendDomain(); // Assuming you have this function
        const url = `https://${apiBase}/score/${score_id}`;

        try {
            const response = await fetch(url);
            const scoreData = await response.text();
            localStorage.setItem(url, scoreData);
            this.setState({ score_id });
        } catch (error) {
            console.error('Error fetching score:', error);
        } finally {
            this.isFetching = false;
        }
    }

    private toggleView() {
        this.setState((prevState: WebrtcConnectorState) => ({ showScore: !prevState.showScore }));
    }

    private start() {
        console.log('Establishing peer connection');
        let pc = this.createPeerConnection();
        this.pc = pc

        let dataChannelParams: RTCDataChannelInit = {ordered: true, negotiated: true, id: 0};

        this.dc = pc.createDataChannel('chat', dataChannelParams);

        if (this.dc) {
            this.dc.onclose = () => {
                console.log('Data channel closed.');
                this.props.onDisconnect();
            };

            this.dc.onopen = (ev: any) => {
                console.log('Data channel opened', ev);

                console.log('Successfully connected');
                this.props.onConnect(true);
                let message = JSON.stringify({
                    type: "register",
                    data: {
                        user_id: this.props.userId,
                        timestamp: new Date().getTime() / 1000.0
                    }
                });
                try {
                    this.dc && this.dc.send(message);
                    console.log('sent datachannel message', message);
                } catch (e) {
                    console.error(e);
                }
            };
            this.dc.onerror = (er: any) => {
                console.log('Data channel error', er);
            }
            this.dc.onmessage = (evt: any) => {
                try {
                    const json = JSON.parse(evt.data);
                    if (json.type === 'status') {

                        this.props.onAudioMatched(json.data.audio_matched);
                        this.props.onFaceRecognized(json.data.face_recognized);
                        json.data.video_bitrate && this.props.onBitrateReceived(json.data.video_bitrate);
                        this.props.onSpeechRecognitionResultReceived(json.data.speech_recognition_result);
                        this.props.onDialogResponseReceived(json.data.dialog_response);
                        this.props.onTempoReceived(json.data.tempo);

                        // Handle emotional response data
                        if (json.data.emotional_response) {
                            const emotionsList = [
                                'anger', 'anxious', 'calm', 'capricious', 'comical', 'decisive', 'depressed', 'dreamy',
                                'elegant', 'enthusiastic', 'fierce', 'gentle', 'happy', 'harsh', 'heavy', 'impetuous',
                                'important', 'kind', 'longing', 'marching', 'melancholic', 'melodious', 'mysterious',
                                'nostalgia', 'passionately', 'rapidly', 'reflective', 'religious', 'sad', 'sincere',
                                'sleepy', 'solemn', 'triumphantly'
                            ];                            // exclude emotions not on the list
                            for (let key in json.data.emotional_response) {
                                if (!emotionsList.includes(key)) {
                                    delete json.data.emotional_response[key];
                                }
                            }
                            // normalise the emotional response data. iterate over keys, compute sum of all values. normalise every value by dividing by sum.
                            let m = 0;
                            for (let key in json.data.emotional_response) {
                                m = Math.max(m, Number(json.data.emotional_response[key]));
                            }
                            for (let key in json.data.emotional_response) {
                                json.data.emotional_response[key] = Number(json.data.emotional_response[key]) / m;
                            }
                            console.log('emotional response', json.data.emotional_response);
                            this.setState({ emotionalResponse: json.data.emotional_response });


                            // Handle score_id
                            if (json.data.score_id && json.data.score_id !== this.state.score_id) {
                                this.fetchAndCacheScore(json.data.score_id);
                            }

                            this.setState({
                                currentBeat: json.data.beat,
                                currentBar: json.data.bar,
                            });
                        }
                    } else if (json.type === 'stream_name') {
                        console.log('Stream name msg i guess')
                    } else if (json.type === 'gesture_matches') {
                        const matches: IGestureMatch[] = json.data.gesture_matches;
                        const sortedMatches = matches.sort((a: IGestureMatch, b: IGestureMatch) =>
                            !a.context_duration || !b.context_duration ? 0 :
                                a.context_duration < b.context_duration
                                    ? -1
                                    : a.context_duration > b.context_duration
                                        ? 1
                                        : 0);
                        this.props.onMatch(sortedMatches);
                    } else if (json.type === 'offer') {
                        // Handle server's offer
                        if (!this.pc) throw new Error("pc undefined");
                        const remoteDesc = new RTCSessionDescription(json.data);
                        this.pc.setRemoteDescription(remoteDesc).then(async () => {
                            if (!this.pc) throw new Error("pc undefined");
                            const answer = await this.pc.createAnswer();
                            await this.pc.setLocalDescription(answer);
                            // Send the answer back to the server via the data channel
                            const message = {
                                type: 'answer',
                                data: this.pc && this.pc.localDescription
                            };
                            this.dc && this.dc.send(JSON.stringify(message));
                        }).catch((error) => {
                            console.error('Error handling offer:', error);
                        });
                    } else if (json.type === 'answer') {
                        // Handle answer from server
                        if (!this.pc) throw new Error("pc undefined");
                        const remoteDesc = new RTCSessionDescription(json.data);
                        this.pc.setRemoteDescription(remoteDesc).catch((error) => {
                            console.error('Error setting remote description:', error);
                        });
                    } else {
                        console.log('Got unknown message type.')
                    }
                } catch (e) {
                    console.log(`Error on receiving data channel message: ${evt.data}`, e);
                }
            };
        }

        navigator.mediaDevices.getUserMedia({ audio: this.constraints.audio }).then(async (stream) => {

            stream.getTracks().forEach((track) => {
                this.pc && this.pc.addTrack(track, stream) && console.log("added track", track);
            });

            return await this.negotiate();
        }, function (err) {
            console.error('Could not acquire media: ' + err);
        });
    }

    public startVideoCapture() {
        navigator.mediaDevices.getUserMedia({ video: this.videoConstraints }).then((stream) => {
            stream.getTracks().forEach((track) => {
                this.pc && this.pc.addTrack(track, stream);
                console.log("added video track", track);
            });

            // Set up local video element
            this.video.current.setAttribute('autoplay', '');
            this.video.current.setAttribute('playsinline', '');
            this.video.current.muted = true;
            this.video.current.volume = 0;
            this.video.current.srcObject = stream;
            this.video.current.onloadedmetadata = (e: any) => {
                this.video && this.video.current && this.video.current.play();
                if (isMobileSafari || isSafari) {
                    this.video.current.onclick = (e: any) => this.video.current.play();
                }
            };

        }).catch((err) => {
            console.error('Error accessing video camera:', err);
        });
    }

    public stopVideoCapture() {
        // Remove video tracks from peer connection
        if (this.pc) {
            const senders = this.pc.getSenders();
            senders.forEach((sender) => {
                if (sender.track && sender.track.kind === 'video') {
                    sender.track.stop();
                    this.pc && this.pc.removeTrack(sender);
                }
            });
        }

        // Stop local video stream
        if (this.video.current && this.video.current.srcObject) {
            const stream = this.video.current.srcObject as MediaStream;
            stream.getTracks().forEach((track) => track.stop());
            this.video.current.srcObject = null;
        }
    }


    private stop() {
        console.info('stop()');

        this.dc && this.dc.close();

        this.pc && this.pc.getTransceivers && this.pc.getTransceivers().forEach((transceiver: any) => {
            if (transceiver.stop) {
                try {
                    transceiver.stop()
                } catch (e) {
                    console.error(e);
                }
            }
        })

        this.pc && this.pc.getSenders().forEach((sender: RTCRtpSender) => {
            try {
                sender.track && sender.track.stop();
            } catch (e) {
                console.error(e);
            }
        });

        // setTimeout(() => {
        try {
            this.pc && this.pc.close()
        } catch (e) {
            console.error(e);
        }
        // }, 500);
    }

    private async negotiate() {
        if (!this.pc) throw new Error("pc undefined");

        // Create offer
        const offer = await this.pc.createOffer();

        if (!offer.sdp) throw new Error("Offer SDP is undefined");

        // Modify SDP for bandwidth and codec parameters
        let bandwidth = {
            screen: 300,
            audio: 100,
            video: 1024
        };
        let isScreenSharing = false;
        let sdp = BandwidthHandler.setApplicationSpecificBandwidth(offer.sdp, bandwidth, isScreenSharing);
        sdp = BandwidthHandler.setOpusAttributes(sdp, { maxaveragebitrate: 510000 });
        sdp = BandwidthHandler.setVideoBitrates(sdp, { min: 100, max: 1024 });

        // Add AbsCaptureTimeExtension consistently across all media sections
        const lines = sdp.split('\r\n');
        const newLines = [];
        let currentMediaSection = '';

        for (const line of lines) {
            if (line.startsWith('m=')) {
                currentMediaSection = line.split(' ')[1];
            }

            if (line.startsWith('a=extmap:')) {
                // Skip existing extmap lines
                continue;
            }

            newLines.push(line);

            if (line.startsWith('m=')) {
                // Add the AbsCaptureTimeExtension right after the media line
                newLines.push('a=extmap:2 urn:ietf:params:rtp-hdrext:abs-capture-time');
            }
        }

        sdp = newLines.join('\r\n');

        // Set the modified SDP
        const modifiedOffer = new RTCSessionDescription({
            type: 'offer',
            sdp: sdp
        });

        await this.pc.setLocalDescription(modifiedOffer);

        // Wait for ICE gathering to complete
        await new Promise<void>((resolve) => {
            if (!this.pc) throw new Error("pc undefined");
            if (this.pc.iceGatheringState === 'complete') {
                resolve();
            } else {
                const checkState = () => {
                    if (!this.pc) throw new Error("pc undefined");
                    if (this.pc.iceGatheringState === 'complete') {
                        this.pc.removeEventListener('icegatheringstatechange', checkState);
                        resolve();
                    }
                };
                this.pc.addEventListener('icegatheringstatechange', checkState.bind(this));
            }
        });

        const offerDescription = this.pc.localDescription;
        if (!offerDescription) throw new Error("Offer description is undefined");

        let body = JSON.stringify({
            sdp: offerDescription.sdp,
            type: offerDescription.type,
        });

        const domain = getBackendDomain();
        const response = await fetch('https://' + domain + '/offer', {
            body: body,
            headers: { 'Content-Type': 'application/json' },
            method: 'POST'
        });

        const answer = await response.json();
        await this.pc.setRemoteDescription(answer);
    }


    private createPeerConnection() {
        let config = {
            sdpSemantics: 'unified-plan'
        };

        if (this.state.useStun) {
            // @ts-ignore
            config.iceServers = [{
                urls: ['stun:stun.l.google.com:19302']
            }];
        }


        // @ts-ignore
        let pc = new RTCPeerConnection(config);

        // register some listeners to help debugging
        pc.addEventListener('icegatheringstatechange', function () {
            console.log('icegatheringstatechange', pc.iceGatheringState);
        }, false);

        pc.addEventListener('iceconnectionstatechange', () => {
            console.log('iceconnectionstatechange', pc.iceConnectionState);
            if (pc.iceConnectionState === 'disconnected') {
                this.props.onDisconnect();
            }
        }, false);

        pc.addEventListener('signalingstatechange', () => {
            console.log('signalingstatechange', pc.signalingState);
            if (pc.signalingState === 'closed') {
                this.props.onDisconnect();
            }
        }, false);

        // connect audio / video
        pc.addEventListener('track', (evt: RTCTrackEvent) => {
            if (evt.track.kind === 'video') {
                console.log('wiring video echo out');
                this.video_echo.current.setAttribute('autoplay', '');
                this.video_echo.current.setAttribute('muted', '');
                this.video_echo.current.setAttribute('playsinline', '');
                this.video_echo.current.srcObject = evt.streams[0];
                this.video_echo.current.onloadedmetadata = (e: any) => {
                    this.video_echo && this.video_echo.current && this.video_echo.current.play();
                };
            } else {
                console.log('wiring audio out');
                this.audio.current.srcObject = evt.streams[0];
                const gain = isIOS && osVersion === "14" ? 10 : 1;
                this.connectToSpeaker(evt.streams[0], gain);
            }
        });

        // After creating the PeerConnection (pc)
        pc.onnegotiationneeded = async () => {
            try {
                if (!this.pc) throw new Error("pc undefined");

                const offer = await this.pc.createOffer();
                await this.pc.setLocalDescription(offer);

                // Wait for ICE gathering to complete
                await new Promise<void>((resolve) => {
                    if (!this.pc) throw new Error("pc undefined");

                    if (this.pc.iceGatheringState === 'complete') {
                        resolve();
                    } else {
                        const checkState = () => {
                            if (this.pc && this.pc.iceGatheringState === 'complete') {
                                this.pc.removeEventListener('icegatheringstatechange', checkState);
                                resolve();
                            }
                        };
                        this.pc.addEventListener('icegatheringstatechange', checkState);
                    }
                });

                // Send the offer via data channel
                if (this.dc && this.dc.readyState === 'open') {
                    const message = {
                        type: 'offer',
                        data: this.pc.localDescription
                    };
                    this.dc.send(JSON.stringify(message));
                } else {
                    console.warn('Data channel not open; cannot send offer');
                }
            } catch (err) {
                console.error('Error during negotiationneeded event:', err);
            }
        };


        return pc;
    }

    public reportTrainingPlayback(youtubeId: string,
                                  timestamp: number,
                                  offset: number,
                                  duration: number,
                                  currentTimestamp: number) {
        // return; // do not yet report somewhat erroneous values. TODO: fix
        const msg = {
            type: 'playback',
            data: {
                youtubeId,
                timestamp,
                offset,
                length: duration,
                currentTimestamp
            }
        };
        this._sendMessage(msg);
    }

    public reportModeChange(mode: string) {
        const msg = {
            type: 'mode',
            data: {
                mode
            }
        };
        this._sendMessage(msg);
    }

    public reportControlState(enabled: boolean) {
        const msg = {
            type: 'controlMode',
            data: {
                enabled
            }
        }
        this._sendMessage(msg);
    }

    public sendMusicIntent(intent: any) {
        const msg = {
            type: 'musicIntent',
            data: intent
        };
        this._sendMessage(msg);
    }

    public sendFreeText(text: string) {
        const msg = {
            type: 'freeText',
            data: {
                value: text
            }
        };
        this._sendMessage(msg);
    }

    public sendPlaybackControl(action: string, target?: string, value?: number) {
        const msg = {
            type: 'musicIntent',
            data: {
                action: action,
                target: target,
                value: value
            }
        };
        this._sendMessage(msg);
    }

    public sendPerformanceDirection(direction: string) {
        const msg = {
            type: 'musicIntent',
            data: {
                action: 'performance_direction',
                performance_direction: direction
            }
        };
        this._sendMessage(msg);
    }

    private _sendMessage(msg: {type: string, data: object}) {
        if (this.dc && this.dc.readyState === 'open') {
            console.log(msg.type, msg);
            this.dc.send(JSON.stringify(msg))
        } else {
            console.log('Failed to send message of type ' + msg.type);
        }
    }

    public connectToSpeaker = function (remoteAudioStream: MediaStream, gain: number) {
        const context: AudioContext = new AudioContext();
        const audioNode = context.createMediaStreamSource(remoteAudioStream);
        const gainNode: GainNode = context.createGain();
        // some device volume too low ex) iPad
        gainNode.gain.value = gain;
        audioNode.connect(gainNode);
        gainNode.connect(context.destination);
    }

    public render() {
        let video = <video ref={this.video} style={{
            height: '46vh',
            transform: 'rotateY(180deg)',
            WebkitTransform: 'rotateY(180deg)',
            MozTransform: 'rotateY(180deg)',
            display: this.props.backendConnected && this.video.current?.srcObject ? 'block' : 'none'
        }} />;

        let video_echo = <video ref={this.video_echo} style={{
            height: '46vh',
            transform: 'rotateY(180deg)',
            WebkitTransform: 'rotateY(180deg)',
            MozTransform: 'rotateY(180deg)',
            display: this.video_echo.current?.srcObject ? 'block' : 'none'
        }} />;

        let audio = <audio ref={this.audio} />;

        let placeholder = (
            <div className="Placeholder"
                 style={{ display: this.props.backendConnected ? 'none' : 'block' }}>
                <div>
                    <FormattedMessage
                        id="app.webrtc.noConnection"
                        defaultMessage="No connection."
                    />
                </div>
                <div>
                    <FormattedMessage
                        id="app.webrtc.reconnectAttempt"
                        defaultMessage="Attempting to connect..."
                    />
                </div>
            </div>
        );

        const scoreViewer = this.state.showScore && this.state.score_id ? (
            <div style={{ height: '46vh', width: '100%', overflow: 'auto' }}>
                <ScoreViewer
                    score_id={this.state.score_id}
                    currentBeat={this.state.currentBeat}
                    currentBar={this.state.currentBar}
                    getBackendDomain={getBackendDomain}
                />
            </div>
        ) : null;


        const emotionView = !this.state.showScore ? (
            <div style={{ height: '46vh', width: '100%' }}>
                <EmotionVoronoi
                    emotionalResponse={this.state.emotionalResponse}
                    onEmotionSelected={this.sendPerformanceDirection.bind(this)}
                />
            </div>
        ) : null;

        const viewToggle = this.state.score_id ? (
            <div className="view-toggle" style={{
                //position: 'absolute',
                left: '10px',
                bottom: '10px',
                zIndex: 1000
            }}>
                <button onClick={() => this.toggleView()} style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '24px',
                    color: 'white'
                }}>
                    {this.state.showScore ? <FaTheaterMasks /> : <FaMusic />}
                </button>
            </div>
        ) : null;

        return (
            <div className="Main-webrtc" style={{ position: 'relative' }}>
                <div style={{
                    display: this.props.backendConnected ? 'block' : 'none',
                    height: '46vh',
                    width: '100%',
                }}>
                    {scoreViewer}
                    {emotionView}
                </div>
                {video}
                {audio}
                {placeholder}
                {viewToggle}
            </div>
        );
    }

}


export default WebrtcConnector;
